import { Box, Grid, Typography, Link, Container, useMediaQuery } from '@mui/material';
import { Twitter, Instagram } from '@mui/icons-material';
import lux from '../../assets/lux.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapPin, faMobilePhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import Social from './Social';

function Footer() {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    const { i18n } = useTranslation();
    const phoneNumber = '393762023340';
    
    // Definisci i messaggi per ogni lingua usando i18n
    const messages = {
      it: "Ciao! Vorrei ricevere maggiori informazioni.",
      en: "Hello! I would like more information."
    };
  
    // Sceglie il messaggio in base alla lingua corrente
    const message = messages[i18n.language.slice(0, 2)] || messages.it; // Fallback alla lingua italiana se non trovato
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;


    return (
        <div style={{
            bgcolor: 'black',
            padding: 3,
            marginTop: 2,
            color: '#b8b3af',
            borderRadius: "0.5rem",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Grid container spacing={isMobile ? 1 : 3} justifyContent="space-around" alignItems="center">
                <Grid item xs={12} md={4} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                    <Typography variant="subtitle1" sx={{ color: '#6490bc', fontWeight: 'bold' }}>
                        Contatti
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                    <img src={lux} alt='logo' style={{ width: "200px", objectFit: "contain" }} />

                    

                        <br/>
                       {/* <FontAwesomeIcon style={{marginRight: '10px'}} icon={faMapPin}/> Via Matteo della Corte, 34<br/>
                       0124 - Roma, RM, ITALY<br/> <br/> */}
                       <FontAwesomeIcon style={{marginRight: '10px'}} icon={faMobilePhone}/> Tel: +39 376-2023340 - 
                        <Link style={{textDecoration: "none", color: "limegreen"}} href={whatsappUrl}>
                        <FontAwesomeIcon href={whatsappUrl} icon={faWhatsapp} style={{marginLeft: '8px'}} /><br/>
                       </Link>
                       <FontAwesomeIcon style={{marginRight: '10px'}} icon={faEnvelope}/> Email: <Link href="mailto:luxuryagencyrome@gmail.com" color="inherit">luxuryagencyrome@gmail.com</Link>
                    </Typography>
                </Grid>

<div style={{
    alignItems: "center", marginTop: isMobile ? '1rem' : undefined}}>
                  <Social/>
            </div>
            </Grid>
            <Typography variant="body2" align="center" sx={{ pt: 2, fontSize: isMobile ? '12px' : '14px', color: 'grey', mt: 1 }}>
                © {new Date().getFullYear()} Luxury Agency Rome S.r.l - Tutti i diritti riservati.
            </Typography>
        </div>
    );
}

export default Footer;
